(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("rxjs"), require("rxjs/operators"));
	else if(typeof define === 'function' && define.amd)
		define("kenticoCloudDelivery", ["rxjs", "rxjs/operators"], factory);
	else if(typeof exports === 'object')
		exports["kenticoCloudDelivery"] = factory(require("rxjs"), require("rxjs/operators"));
	else
		root["kenticoCloudDelivery"] = factory(root["rxjs"], root["rxjs"]["operators"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 